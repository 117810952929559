<template>
  <div class="page">
    <div class="pointes-list">
      <div class="page__title">
        {{ getModuleConfig("records_page.form_title") }}
      </div>
      <div
        class="page__desc"
        v-html="getModuleConfig('records_page.form_desc')"
        v-if="getModuleConfig('records_page.form_desc')"
      ></div>
      <div class="page__content" v-if="getModuleConfig('records_page.content')" v-html="getModuleConfig('records_page.content')"></div>
      <div class="records-list">
        <div class="record s-mb-3" v-for="item in pointLogs" :key="item.id">
          <div>
            <div class="record__title">{{ item.name }}</div>
            <div class="record__desc">
              <div class="record__date">交易日期 {{ item.date }}</div>
            </div>
          </div>
          <div class="s-text-primary record__point">
            {{ item.point }}
          </div>
        </div>
      </div>
      <PointPagination v-model="page" :totalPage="totalPage"></PointPagination>
    </div>
  </div>
</template>

<script>
import PointPagination from "@/pages/Liff/Point/PointPagination";
import moment from "moment";
import pointApi from "@/apis/liff/v2/point";
import pointMixin from "@/mixins/liff/point";

export default {
  mixins: [pointMixin],
  components: {
    PointPagination,
  },
  data() {
    return {
      pointLogs: [
        /* {
         *   id: 1,
         *   name: "購物贈點",
         *   date: "2021/10/05",
         *   point: 182,
         * },
         * {
         *   id: 2,
         *   name: "購物贈點",
         *   date: "2021/10/05",
         *   point: 182,
         * },
         * {
         *   id: 3,
         *   name: "購物贈點",
         *   date: "2021/10/05",
         *   point: 182,
         * },
         * {
         *   id: 4,
         *   name: "購物贈點",
         *   date: "2021/10/05",
         *   point: 182,
         * },
         * {
         *   id: 5,
         *   name: "購物贈點",
         *   date: "2021/10/05",
         *   point: 182,
         * }, */
      ],
      page: 1,
      totalPage: 1,
      eventCode: this.$route.query.event ?? null,
    };
  },
  watch: {
    page() {
      this.fetchPointLogs();
    },
  },
  mounted() {
    this.fetchPointLogs();
  },
  methods: {
    async fetchPointLogs() {
      try {
        let res = await pointApi.listPointsLogs(this.eventCode, this.page);
        let point_logs = res.data;
        this.totalPage = res.meta.last_page;

        if (!point_logs || !point_logs.length) {
          this.pointLogs = [];
          return;
        }

        this.pointLogs = point_logs
          .map((pl) => ({
            id: pl.id,
            name: pl.description,
            point: pl.batch_total_points,
            type: pl.type,
            date: moment(pl.created_at).format("YYYY/MM/DD"),
            status: "finish",
          }))

        if (res.links.next === null) {
          this.noMore = true;
        }
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "錯誤",
          text: "未支援的資料來源",
        });
      }
    },
  },
};
</script>

<style scoped>
.record {
  box-shadow: rgb(from var(--s-primary) r g b / 0.2) 0px 2px 8px 0px;
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/shared/components/_fields.scss';

.page {
  padding: 16px 12px;
  /* margin-bottom: 12px; */
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}

.records-list {
  padding: 12px;
  padding-bottom: 0;
  background: #fff;
}
.record {
  border-bottom: 1px solid #e5e5ea;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 5px;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }

  &__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    div {
      margin-bottom: 8px;
    }
  }

  &__point {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
}
</style>
